<template>
  <div>
    <div class="head_section">
      <h2 id="dkBody" class="tit_subdesc">
        <span class="txt_number">요청번호 : {{ docNo }}</span>
        <span class="txt_info">{{ _.unescape(itemData.docTitle) }}</span>
      </h2>
    </div>
    <!-- 결재현황 -->
    <div class="section_g">
      <PayStatus
        :isApprover="false"
        :apprType="apprType"
        :can-approve-edit="canAproveEdit"
        :doc-no="docNo"
        :doc-flag="docFlag"
        :approval-line="approvalLine"
        :add-approver-list="addApproverList"
        :viewer-list="viewerList"
        @searchOrganization="searchOrganization"
        @popDeleteapprover="popDeleteapprover"
      />
    </div>
    <!-- 승인 반려 코멘트 -->
    <RejectComment v-if="whetherExistComment" :approval-line="approvalLine" />
    <!-- 구매요청 기본정보 -->
    <DraftReqeustInfo :draftData="itemData" />
    <!-- 구매요청품목 -->
    <!-- <DraftPurchaseGoodsList v-if="model.assetType === assetType.INFRA_ASSET" :draftData="model" /> -->
    <!-- 정산정보 -->
    <!-- <DraftPurchasePayment :draftData="model" /> -->
    <!-- PdfViewer -->
    <PdfViewer v-if="itemData.targetPk" :pdfInfoObj="itemData.pdfHistoryResult" />
    <!-- 첨부파일 -->
    <DraftFile :attachFileList.sync="itemData.attachFileList" />
    <!-- 나의 코멘트 -->
    <MyComment :comment.sync="apprReason" />
    <div class="wrap_btn type_bottom">
      <div class="pos_left">
        <button type="button" class="btn_large btn_fourthly" @click.prevent="gotoList()">
          목록
        </button>
      </div>
      <div v-if="canApprove" class="pos_right">
        <!-- <button :disabled="partReject" type="button" class="btn_large btn_secondary" @click.prevent="popUpRejectLayer">반려</button> -->
        <!-- <button
          :disabled="partReject"
          type="button"
          class="btn_large btn_secondary"
          @click.prevent="popUpPrevRejectLayer"
        >
          이전반려
        </button> -->
        <button
          :disabled="partReject"
          type="button"
          class="btn_large btn_secondary"
          @click.prevent="popUpAllRejectLayer"
        >
          전체반려
        </button>
        <!-- <button
          :disabled="partReject"
          type="button"
          class="btn_large btn_secondary"
          @click.prevent="popUpDraftRejectLayer"
        >
          기안자반려
        </button> -->
        <button
          :disabled="partReject"
          type="button"
          class="btn_large btn_primary"
          @click.prevent="popUpApproveLayer"
        >
          승인
        </button>
        <!-- <button
          v-if="!isNotSuperOrAdmin"
          type="button"
          class="btn_large btn_primary"
          @click.prevent="onClickAnnounceRegister"
        >
          공고 등록
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import PayStatus from "@/_approval/components/shared/view/PayStatus";
import RejectComment from "@/_approval/components/shared/view/RejectComment";
import DraftReqeustInfo from "@/_approval/components/payment/view/DraftReqeustInfo";
import DraftPurchaseGoodsList from "@/_approval/components/payment/view/DraftPurchaseGoodsList";
import DraftPurchasePayment from "@/_approval/components/payment/view/DraftPurchasePayment";
import DraftFile from "@/_approval/components/payment/view/DraftFile";
import MyComment from "@/_approval/components/shared/view/MyComment";
import PurchaseRequestDetailMixin from "@/_approval/components/shared/view/PurchaseRequestDetailMixin";
import DraftWriteModel from "@/_approval/pages/payment/write/DraftWriteModel";
import commDocs from "@/_approval/mixins/common/commDocs";
import {
  assetType,
  approvalStatus,
  aprvlLineType,
  docStatus,
  targetGubun,
} from "@/_approval/const/const";
import ApiService from "@/services/ApiService";
import { ALERT_ACTION } from "@/store/modules/alert/action";

import LocalStorageManager from "@/LocalStorageManager";
import PdfViewer from "@/components/shared/PdfViewer";

export default {
  components: {
    PayStatus,
    RejectComment,
    DraftReqeustInfo,
    DraftPurchaseGoodsList,
    DraftPurchasePayment,
    DraftFile,
    MyComment,
    PdfViewer,
  },
  mixins: [PurchaseRequestDetailMixin, commDocs],
  computed: {
    ...mapState("CommStore", {
      accountId: (state) => state.login.accountId,
    }),
    cloudPrevReject() {
      return (
        this.approvalLine
          .filter((e) => e.aprvlLineType === aprvlLineType.APPROVAL)
          .findIndex((e) => e.accountId === this.accountId.replace(".ke", "")) !== 0
      );
    },
    isNotSuperOrAdmin() {
      return (
        LocalStorageManager.shared.getIsInspect() ||
        LocalStorageManager.shared.getIsRollUser() ||
        LocalStorageManager.shared.getIsRollUser().getIsPartner
      );
    },
  },
  data() {
    return {
      apprType: "",
      apprReason: "",
      nowApprovalInfo: {
        accountId: "",
      },
      docNo: this.$route.params.id,
      itemData: {},
      itemId: "",
      targetPk: "",
      targetGubun: "",
      docStatus: Object.freeze(docStatus),
      partReject: false, // 부분반려 true/false
      model: new DraftWriteModel(),
      approvalLine: [],
      canAproveEdit: false,
      assetType: assetType,
      docFlag: "AE",
    };
  },
  created() {
    this.getData();
    this.updateRead();
  },

  methods: {
    getCurrentApprover() {
      return this.approvalLine.find(
        (e) =>
          e.aprvlLineType === aprvlLineType.APPROVAL &&
          e.aprvlStatus === approvalStatus.RECEIVE_PAYMENT,
      );
    },
    /**
     * 결재 가능한지 여부
     * 현재 결재자인데 대결자가 임명되지 않았거나, 대결자인 경우
     * @return {boolean}
     */

    async getData() {
      this.itemId = this.$route.params.id;
      const path = `${this.$apiPath.APRVL_DOC_LIST}/STD/${this.itemId}`;
      // this.$store.dispatch(PROGRESS_START_ACTION);

      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;

      this.itemData = data;
      this.docNo = data.docNo;
      this.targetGubun = data.targetGubun;
      this.approvalLine = data.aprvlLineList;
      this.model = data;
      return;
    },

    async updateRead() {
      const { docStatus, aprvlStatus } = this.docFlagToCode(this.docFlag);

      const params = {
        targetGubun: targetGubun.STANDARD,
        docNoList: [this.docNo],
        readYn: true,
        docStatus,
        aprvlStatus,
      };

      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_READ, params);
    },
    canApprove() {
      return this.getCurrentApprover().accountId === this.accountId;
    },
    popUpRejectCommentValidate() {
      let layer = {};
      layer.type = "alert";
      layer.content = "승인/반려 코멘트를 입력해주세요";
      this._showLayer(layer);
    },
    popUpPrevRejectLayer() {
      this._showLayer({ type: "confirm", content: "해당 기안을 이전 반려하시겠습니까?" }, () => {
        this.approveDraft(approvalStatus.DRAFT_BEFORE_REJECT, "이전 반려 처리되었습니다.");
      });
    },
    popUpDraftRejectLayer() {
      this._showLayer({ type: "confirm", content: "해당 기안을 기안자 반려하시겠습니까?" }, () => {
        this.approveDraft(approvalStatus.DRAFT_REJECT, "전체 기안자 반려 처리되었습니다.");
      });
    },
    popUpAllRejectLayer() {
      this._showLayer({ type: "confirm", content: "해당 기안을 전체 반려하시겠습니까?" }, () => {
        this.approveDraft(approvalStatus.REJECT, "전체 반려 처리되었습니다.");
      });
    },
    popUpApproveLayer() {
      this._showLayer({ type: "confirm", content: "해당 기안을 승인하시겠습니까?" }, () => {
        this.approveDraft(approvalStatus.APPROVAL_PAYMENT, "기안승인 되었습니다.");
      });
    },
    async approveDraft(aprvlStatus, msg) {
      // 승인 처리전 결재자 추가 적용
      if (aprvlStatus === approvalStatus.APPROVAL_PAYMENT && this.addApproverList.length > 0) {
        for (const addApprover of this.addApproverList.reverse()) {
          const resAdd = await ApiService.shared.post(this.$apiPath.APRVL_LINE, {
            empNo: addApprover.empNo,
            aprvlStatus: approvalStatus.APPROVAL_PAYMENT,
            aprvlLineType: aprvlLineType.APPROVAL,
            targetGubun: targetGubun.STANDARD,
            docNoList: [this.docNo],
          });
          if (parseInt(resAdd.code) !== 200) {
            this._showLayer({
              type: "alert",
              content: resAdd.text || "결재자 추가 실패했습니다. 관리자에게 문의하세요",
            });
            return;
          }
        }
      }

      const res = await ApiService.shared.post(this.$apiPath.APRVL_LINE_PROC, {
        aprvlStatus,
        reason: this.apprReason,
        targetGubun: targetGubun.STANDARD,
        docNo: this.docNo,
      });
      if (parseInt(res.code) !== 200) {
        this._showLayer({
          type: "alert",
          content: res.text || "승인에 실패했습니다. 관리자에게 문의하세요",
        });
        return;
      }

      if (!this.checkNormalResponse(res)) {
        return;
      }
      // this._showToast({content: msg});

      this.sendExternalErp();
      //
    },

    async sendExternalErp() {
      const path = this.$apiPath.EXTERNAL_ERP;
      const result = await ApiService.shared.put(
        `${path}/${this.model.targetGubun}/${this.model.targetPk}`,
      );
      const { code, data, text } = result;
      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
          text: text,
        });
      }

      await this.$router.push(this.$paymentRouterPath.PAYMENT_APPROVAL_RECEIVE);
    },

    popUpRejectLayer() {
      if (this.partReject) return; // 부분반려 체크했으면 반응 x
      if (this.apprReason === "") {
        this.popUpRejectCommentValidate(); // 반려시 코멘트 공백인 경우 알람창
        return;
      }
      let layer = {};
      layer.type = "confirm";
      layer.content = "해당 기안을 반려하시겠습니까?";
      this._showLayer(layer, this.rejectDraft);
    },
    rejectDraft() {
      axios
        .post("/api/rejectApproval", {
          docNo: this.docNo,
          apprReason: this.apprReason,
        })
        .then((res) => {
          let toast = {};
          toast.content = "반려 되었습니다.";
          this._showToast(toast);
          this.gotoRejectList("apprRejectDocs");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    gotoList() {
      this.$router.push(this.$paymentRouterPath.PAYMENT_APPROVAL_RECEIVE);
    },
    gotoRejectList() {
      this.$router.push(this.$paymentRouterPath.PAYMENT_APPROVAL_REJECT);
    },
    gotoIngList() {
      this.$router.push(this.$paymentRouterPath.PAYMENT_APPROVAL_ING);
    },
    async onClickAnnounceRegister() {
      const number = await this.getNewAnnounceNumber();
      const path = `${this.$routerPath.ANNOUNCE_WRITE}/${number}?requestCid=${this.docNo}`;
      this.$router.push(path);
      // this.movePageWithPath(`${this.$routerPath.ANNOUNCE_WRITE}/${number}?requestCid=${this.docNo}`);
    },
    async getNewAnnounceNumber() {
      const path = this.$apiPath.CREATE_ANNOUNCE_NUMBER;
      const result = await ApiService.shared.getData(path);
      if (!result.data) return null;

      const newNumber = result.data.postCid;
      return newNumber;
    },
  },
};
</script>
